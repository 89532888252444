<template>
    <div class="columns is-mobile is-multiline" v-if="readOnly">
        <template v-if="isset(()=>this.deviceConfiguration.temperature) && (deviceConfiguration.temperature.capabilities.periodic || deviceConfiguration.temperature.capabilities.thresholds)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Temperature')"></div></div>
            <template v-if="deviceConfiguration.temperature.capabilities.periodic">
                <div class="column is-half"><label class="label">{{$trans('Temperature reporting frequency')}}:</label></div>
                <div class="column is-half">{{ timeString(deviceConfiguration.temperature.configuration.periodic, $trans('Periodic temperature reporting is disabled')) }}</div>
            </template>
            <template v-if="deviceConfiguration.temperature.capabilities.thresholds">
                <div class="column is-half"><label class="label">{{$trans('Temperature limits checking')}}:</label></div>
                <div class="column is-half">{{ rangeString(this.deviceConfiguration.temperature.configuration.thresholds, "°C", $trans('Temperature limits checking is disabled'), 'temperature') }}</div>
            </template>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.humidity) && (deviceConfiguration.humidity.capabilities.periodic || deviceConfiguration.humidity.capabilities.thresholds)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Humidity')"></div></div>
            <template v-if="deviceConfiguration.humidity.capabilities.periodic">
                <div class="column is-half"><label class="label">{{$trans('Humidity reporting frequency')}}</label></div>
                <div class="column is-half">{{ timeString(deviceConfiguration.humidity.configuration.periodic, $trans('Periodic humidity reporting is disabled')) }}</div>
            </template>
            <template v-if="deviceConfiguration.humidity.capabilities.thresholds">
                <div class="column is-half"><label class="label">{{$trans('Humidity limits checking')}}</label></div>
                <div class="column is-half">{{ rangeString(this.deviceConfiguration.humidity.configuration.thresholds, "%", $trans('Humidity limits checking is disabled'), 'humidity') }}</div>
            </template>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.accelerometer) && (deviceConfiguration.accelerometer.capabilities.orientation || deviceConfiguration.accelerometer.capabilities.freefall || deviceConfiguration.accelerometer.capabilities.hit)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Acceleration')"></div></div>
            <template v-if="deviceConfiguration.accelerometer.capabilities.orientation">
                <div class="column is-half"><label class="label">{{$trans('Enable orientation change events')}}:</label></div>
                <div class="column is-half"><icon-component :icon="deviceConfiguration.accelerometer.configuration.orientation"></icon-component></div>
            </template>
            <template v-if="deviceConfiguration.accelerometer.capabilities.freefall">
                <div class="column is-half"><label class="label">{{$trans('Enable freefall events')}}:</label></div>
                <div class="column is-half"><icon-component :icon="deviceConfiguration.accelerometer.configuration.freefall"></icon-component></div>
            </template>
            <template v-if="deviceConfiguration.accelerometer.capabilities.hit">
                <div class="column is-half"><label class="label">{{$trans('Select hit events')}}:</label></div>
                <div class="column is-half">{{hitEvents.find((e)=>e.value === this.deviceConfiguration.accelerometer.configuration.hit).text}}</div>
            </template>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.rfid_reader.url)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Url')"></div></div>
            <div class="column is-half"><label class="label">{{$trans('Url')}}:</label></div>
            <div class="column is-half">{{deviceConfiguration.rfid_reader.url}}</div>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.rfid_handler) && Array.isArray(deviceConfiguration.rfid_handler)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Scripts')"></div></div>
            <div class="column is-full" v-for="(script, i) of deviceConfiguration.rfid_handler" :key="i">
                <h2 class="subtitle">{{script.name}}</h2>
                <table class="table is-fullwidth">
                    <tbody>
                        <tr v-for="(entry, j) of script.script" :key="j">
                            <th class="is-narrow">{{j+1}}</th>
                            <td>
                                <div class="tags are-medium">
                                    <a v-for="(reader, k) of entry" :key="k" :href="$getUrl('frontend:devices:single',{deviceId: reader})" class="tag is-medium is-primary">
                                        <icon-component device-type="rfid_reader"></icon-component>
                                        <span>{{currentDeviceConfiguration.details[reader]}}</span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr><td colspan="2">
                            <label class="label">{{$trans('Delay between group entries [ms]:')}}</label>
                            {{ script.delay }}
                        </td></tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.uwb_main.url)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Url')"></div></div>
            <div class="column is-half"><label class="label">{{$trans('Url')}}:</label></div>
            <div class="column is-half">{{deviceConfiguration.uwb_main.url}}</div>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.uwb_alley_worker) && Array.isArray(deviceConfiguration.uwb_alley_worker)">
            <div class="column is-full py-0"><div class="is-divider my-4" :data-content="$trans('Scripts')"></div></div>
            <div class="column is-full" v-for="(script, i) of deviceConfiguration.uwb_alley_worker" :key="i">
                <h2 class="subtitle">{{script.name}}</h2>
                <table class="table is-fullwidth">
                    <tbody>
                        <tr v-for="(entry, j) of script.script" :key="j">
                            <th class="is-narrow">{{j+1}}</th>
                            <td>
                                <div class="tags are-medium">
                                    <a v-for="(reader, k) of entry" :key="k" :href="$getUrl('frontend:devices:single',{deviceId: reader})" class="tag is-medium is-primary">
                                        <icon-component device-type="uwb_anchor"></icon-component>
                                        <span>{{currentDeviceConfiguration.details[reader]}}</span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr><td colspan="2">
                            <label class="label">{{$trans('Delay between group entries [ms]:')}}</label>
                            {{ script.delay }}
                        </td></tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
    <div v-else>
        <loader-component :show="loader"></loader-component>
        <label class="label">{{$trans('Device configuration')}}</label>
        <div class="notification is-danger is-light" v-if="isPending">
            {{$trans('Configuration displayed below is pending, and was not yet propagated to the device.')}}
        </div>
        <template v-if="showBattery">
            <span>{{$trans('Over here you can see a simple indicator how your current configuration will affect battery lifespan of this device. Full battery indicates, that your configuration is extremly lifespan-friendly. Empty battery, on the other hand - means that this configuration will shorten expected device lifespan.')}}</span>
            <div class="columns is-centered">
                <div class="column is-narrow">
                    <battery-indicator-component :value="configurationBatteryUsage"></battery-indicator-component>
                </div>
            </div>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.temperature) && (deviceConfiguration.temperature.capabilities.periodic || deviceConfiguration.temperature.capabilities.thresholds)">
            <div class="is-divider" :data-content="$trans('Temperature')"></div>
            <template v-if="deviceConfiguration.temperature.capabilities.periodic">
                <label class="label">{{$trans('Temperature reporting frequency')}}</label>
                <slider-component style="margin-left: 68px; margin-right: 23px; margin-top: 1rem;" :options="temperaturePeriodicSliderOptions" @value="(v) => deviceConfiguration.temperature.configuration.periodic = parseInt(v)"></slider-component>
                <div class="notification is-light fit-content" :class="deviceConfiguration.temperature.configuration.periodic > 0 ? 'is-info': ''">
                    <icon-component icon="fas fa-clock"></icon-component>
                    <span>{{ timeString(deviceConfiguration.temperature.configuration.periodic, $trans('Periodic temperature reporting is disabled')) }}</span>
                </div>    
            </template>
            <template v-if="deviceConfiguration.temperature.capabilities.thresholds">
                <label class="label">{{$trans('Temperature limits checking')}}</label>
                <input-component type="checkbox" :label="$trans('Enable lower limit')" :value="temperatureThresholdLowerEnabled" @input="(e)=> $set(this.deviceConfiguration.temperature.configuration.thresholds, 0, e?-10:false)"></input-component>
                <input-component type="checkbox" :label="$trans('Enable upper limit')" :value="temperatureThresholdUpperEnabled" @input="(e)=> $set(this.deviceConfiguration.temperature.configuration.thresholds, 1, e? 10:false)"></input-component>
                <slider-component v-if="temperatureThresholdLowerEnabled || temperatureThresholdUpperEnabled" style="margin-left: 68px; margin-right: 23px; margin-top: 1rem;" :options="temperatureThresholdsSliderOptions" @value="(v)=>thresholdsUpdateValue('temperature',temperatureThresholdLowerEnabled,v)"></slider-component>
                <div class="notification is-light fit-content" :class="deviceConfiguration.temperature.lowerBoundEnabled || deviceConfiguration.temperature.upperBoundEnabled ? 'is-info': ''">
                    <icon-component icon="fas fa-thermometer"></icon-component>
                    <span> {{ rangeString(this.deviceConfiguration.temperature.configuration.thresholds, "°C", $trans('Temperature limits checking is disabled'), 'temperature') }} </span>
                </div>
            </template>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.humidity) && (deviceConfiguration.humidity.capabilities.periodic || deviceConfiguration.humidity.capabilities.thresholds)">
            <div class="is-divider" :data-content="$trans('Humidity')"></div>
            <template v-if="deviceConfiguration.humidity.capabilities.periodic">
                <label class="label">{{$trans('Humidity reporting frequency')}}</label>
                <slider-component style="margin-left: 68px; margin-right: 23px; margin-top: 1rem;" :options="humidityPeriodicSliderOptions" @value="(v) => deviceConfiguration.humidity.configuration.periodic = parseInt(v)"></slider-component>
                <div class="notification is-light fit-content" :class="deviceConfiguration.humidity.configuration.periodic > 0 ? 'is-info': ''">
                    <icon-component icon="fas fa-clock"></icon-component>
                    <span>{{ timeString(deviceConfiguration.humidity.configuration.periodic, $trans('Periodic humidity reporting is disabled')) }}</span>
                </div>    
            </template>
            <template v-if="deviceConfiguration.humidity.capabilities.thresholds">
                <label class="label">{{$trans('Humidity limits checking')}}</label>
                <input-component type="checkbox" :label="$trans('Enable lower limit')" :value="humidityThresholdLowerEnabled" @input="(e)=> $set(this.deviceConfiguration.humidity.configuration.thresholds, 0, e?-10:false)"></input-component>
                <input-component type="checkbox" :label="$trans('Enable upper limit')" :value="humidityThresholdUpperEnabled" @input="(e)=> $set(this.deviceConfiguration.humidity.configuration.thresholds, 1, e? 10:false)"></input-component>
                <slider-component v-if="humidityThresholdLowerEnabled || humidityThresholdUpperEnabled" style="margin-left: 68px; margin-right: 23px; margin-top: 1rem;" :options="humidityThresholdsSliderOptions" @value="(v)=>thresholdsUpdateValue('humidity',humidityThresholdLowerEnabled,v)"></slider-component>
                <div class="notification is-light fit-content" :class="deviceConfiguration.humidity.lowerBoundEnabled || deviceConfiguration.humidity.upperBoundEnabled ? 'is-info': ''">
                    <icon-component icon="fas fa-tint"></icon-component>
                    <span> {{ rangeString(this.deviceConfiguration.humidity.configuration.thresholds, "%", $trans('Humidity limits checking is disabled'), 'humidity') }} </span>
                </div>
            </template>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.accelerometer) && (deviceConfiguration.accelerometer.capabilities.orientation || deviceConfiguration.accelerometer.capabilities.freefall || deviceConfiguration.accelerometer.capabilities.hit)">
            <div class="is-divider" :data-content="$trans('Acceleration')"></div>
            <input-component v-if="deviceConfiguration.accelerometer.capabilities.orientation"  type="checkbox" :label="$trans('Enable orientation change events')" v-model="deviceConfiguration.accelerometer.configuration.orientation"></input-component>
            <input-component v-if="deviceConfiguration.accelerometer.capabilities.freefall"     type="checkbox" :label="$trans('Enable freefall events')"           v-model="deviceConfiguration.accelerometer.configuration.freefall"></input-component>
            <input-component v-if="deviceConfiguration.accelerometer.capabilities.hit"          type="select"   :label="$trans('Select hit events')"                v-model="deviceConfiguration.accelerometer.configuration.hit" :options="hitEvents"></input-component>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.rfid_reader.url)">
            <div class="is-divider" :data-content="$trans('Url')"></div>
            <input-component :label="$trans('Reader read url:')" v-model="deviceConfiguration.rfid_reader.url"></input-component>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.rfid_handler) && Array.isArray(deviceConfiguration.rfid_handler)">
            <div class="is-divider" :data-content="$trans('Program')"></div>
            <div class="columns is-multiline">
                <div class="column is-one-third" v-for="(script, i) in deviceConfiguration.rfid_handler" :key="i">
                    <div class="notification is-primary">
                        <button class="delete" @click="deviceConfiguration.rfid_handler.splice(i, 1)"></button>
                        <input-component v-model="script.name"></input-component>
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr v-for="(entry, j) of script.script" :key="j">
                                <th class="is-narrow">{{j+1}}</th>
                                <td>
                                    <div class="columns is-multiline">
                                        <div class="column is-half" v-for="(reader, k) of entry" :key="k">
                                            <input-component type="autocomplete" v-model="entry[k]" :options="$getUrl('backendDevices',undefined,{type: 'rfid_reader'})" autocomplete-text-field="custom_id" :autocomplete-icon="(d)=>({'device-type':d.devicetype.code})">
                                                <button class="button is-danger" @click="()=>{
                                                    deviceConfiguration.rfid_handler[i].script[j].splice(k, 1)
                                                    if(deviceConfiguration.rfid_handler[i].script[j].length === 0){
                                                        deviceConfiguration.rfid_handler[i].script.splice(j, 1)
                                                    }
                                                }">
                                                    <icon-component icon="fas fa-times"></icon-component>
                                                </button>
                                            </input-component>
                                        </div>
                                    </div>
                                </td>
                                <td class="is-narrow">
                                    <button class="button is-info" @click="entry.push('')">
                                        <icon-component icon="fas fa-plus"></icon-component>
                                    </button>
                                </td>
                            </tr>
                            <tr><td colspan="3">
                                <input-component :label="$trans('Delay between group entries [ms]:')" type="number" :min="0" v-model="script.delay"></input-component>
                            </td></tr>
                            <tr><td colspan="3">
                                <button class="button is-primary is-fullwidth" @click="script.script.push([''])">
                                    <icon-component icon="fas fa-plus"></icon-component>
                                    <span>{{$trans('Add entry')}}</span>
                                </button>
                            </td></tr>
                        </tbody>
                    </table>
                </div>
                <div class="column is-one-third">
                    <button class="button is-primary is-fullwidth" @click="deviceConfiguration.rfid_handler.push({name:'',script:[],delay:100})">
                        <icon-component icon="fas fa-plus"></icon-component>
                        <span>{{$trans('Add script')}}</span>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.uwb_main.url)">
            <div class="is-divider" :data-content="$trans('Url')"></div>
            <input-component :label="$trans('UWB main anchor trigger url:')" v-model="deviceConfiguration.uwb_main.url"></input-component>
        </template>
        <template v-if="isset(()=>this.deviceConfiguration.uwb_alley_worker) && Array.isArray(deviceConfiguration.uwb_alley_worker)">
            <div class="is-divider" :data-content="$trans('Program')"></div>
            <div class="columns is-multiline">
                <div class="column is-one-third" v-for="(script, i) in deviceConfiguration.uwb_alley_worker" :key="i">
                    <div class="notification is-primary">
                        <button class="delete" @click="deviceConfiguration.uwb_alley_worker.splice(i, 1)"></button>
                        <input-component v-model="script.name"></input-component>
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr v-for="(entry, j) of script.script" :key="j">
                                <th class="is-narrow">{{j+1}}</th>
                                <td>
                                    <div class="columns is-multiline">
                                        <div class="column is-half" v-for="(reader, k) of entry" :key="k">
                                            <input-component type="autocomplete" v-model="entry[k]" :options="$getUrl('backendDevices',undefined,{type: 'uwb_main'})" autocomplete-text-field="custom_id" :autocomplete-icon="(d)=>({'device-type':d.devicetype.code})">
                                                <button class="button is-danger" @click="()=>{
                                                    deviceConfiguration.uwb_alley_worker[i].script[j].splice(k, 1)
                                                    if(deviceConfiguration.uwb_alley_worker[i].script[j].length === 0){
                                                        deviceConfiguration.uwb_alley_worker[i].script.splice(j, 1)
                                                    }
                                                }">
                                                    <icon-component icon="fas fa-times"></icon-component>
                                                </button>
                                            </input-component>
                                        </div>
                                    </div>
                                </td>
                                <td class="is-narrow">
                                    <button class="button is-info" @click="entry.push('')">
                                        <icon-component icon="fas fa-plus"></icon-component>
                                    </button>
                                </td>
                            </tr>
                            <tr><td colspan="3">
                                <input-component :label="$trans('Delay between group entries [ms]:')" type="number" :min="0" v-model="script.delay"></input-component>
                            </td></tr>
                            <tr><td colspan="3">
                                <button class="button is-primary is-fullwidth" @click="script.script.push([''])">
                                    <icon-component icon="fas fa-plus"></icon-component>
                                    <span>{{$trans('Add entry')}}</span>
                                </button>
                            </td></tr>
                        </tbody>
                    </table>
                </div>
                <div class="column is-one-third">
                    <button class="button is-primary is-fullwidth" @click="deviceConfiguration.uwb_alley_worker.push({name:'',script:[],delay:100})">
                        <icon-component icon="fas fa-plus"></icon-component>
                        <span>{{$trans('Add script')}}</span>
                    </button>
                </div>
            </div>
        </template>
        <button class="button is-primary is-fullwidth" :class="loader?'is-loading':''" :disabled="!hasChanges" @click="sendConfiguration" style="margin-top: 1em;">{{$trans('Save configuration')}}</button>
    </div>
</template>
<script>
module.exports = {
    name:'configurationComponent',
    props:{
        isPending:{
            type: Boolean,
            default: false
        },
        currentDeviceConfiguration:{
            type: Object,
            required: true
        },
        updateUrl:{
            type: String
        },
        showBattery:{
            type: Boolean,
            default: true
        },
        readOnly:{
            type: Boolean,
            default: false
        }
    },
    watch:{
        currentDeviceConfiguration:{
            deep: true,
            immediate: true,
            handler: function(){
                this.snappedDeviceConfiguration = JSON.stringify(isset(()=>this.currentDeviceConfiguration.params) ? this.currentDeviceConfiguration.params : this.currentDeviceConfiguration);
                this.deviceConfiguration = JSON.parse(this.snappedDeviceConfiguration);
            }
        },
        temperatureThresholdLowerEnabled(){
            this.temperatureThresholdsSliderOptions = this.temperatureThresholdsUpdateSlider();
        },
        temperatureThresholdUpperEnabled(){
            this.temperatureThresholdsSliderOptions = this.temperatureThresholdsUpdateSlider();
        },
        'deviceConfiguration.temperature.capabilities':{
            deep: true,
            handler(){
                this.temperatureThresholdsSliderOptions = this.temperatureThresholdsUpdateSlider();
            }
        },
        humidityThresholdLowerEnabled(){
            this.humidityThresholdsSliderOptions = this.humidityThresholdsUpdateSlider();
        },
        humidityThresholdUpperEnabled(){
            this.humidityThresholdsSliderOptions = this.humidityThresholdsUpdateSlider();
        },
        'deviceConfiguration.humidity.capabilities':{
            deep: true,
            handler(){
                this.humidityThresholdsSliderOptions = this.humidityThresholdsUpdateSlider();
            }
        }
    },
    computed:{
        configurationBatteryUsage(){
            const map = function(x, in_min, in_max, out_min, out_max) {
                return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
            }
            let ret = 1;
            if(getIfIsset(()=>this.deviceConfiguration.temperature.capabilities.periodic,false))
                ret -= this.deviceConfiguration.temperature.configuration.periodic ? map(this.deviceConfiguration.temperature.configuration.periodic, 0, 255, 0.2, 0) : 0;
            if(getIfIsset(()=>this.deviceConfiguration.temperature.capabilities.thresholds,false))
                ret -= (this.temperatureThresholdLowerEnabled || this.temperatureThresholdUpperEnabled) ? 0.1 : 0;
            if(getIfIsset(()=>this.deviceConfiguration.accelerometer.capabilities.orientation,false))
                ret -= this.deviceConfiguration.accelerometer.configuration.orientation ? 0.1 : 0;
            if(getIfIsset(()=>this.deviceConfiguration.accelerometer.capabilities.freefall,false))
                ret -= this.deviceConfiguration.accelerometer.configuration.freefall ? 0.1 : 0;
            if(getIfIsset(()=>this.deviceConfiguration.accelerometer.capabilities.hit,false))
                ret -= this.deviceConfiguration.accelerometer.configuration.hit !== 0 ? 0.1 : 0;
            return ret;
        },
        temperatureThresholdLowerEnabled(){
            return isset(()=>this.deviceConfiguration.temperature.configuration.thresholds[0]) && this.deviceConfiguration.temperature.configuration.thresholds[0] !== false;
        },
        temperatureThresholdUpperEnabled(){
            return isset(()=>this.deviceConfiguration.temperature.configuration.thresholds[1]) && this.deviceConfiguration.temperature.configuration.thresholds[1] !== false;
        },
        humidityThresholdLowerEnabled(){
            return isset(()=>this.deviceConfiguration.humidity.configuration.thresholds[0]) && this.deviceConfiguration.humidity.configuration.thresholds[0] !== false;
        },
        humidityThresholdUpperEnabled(){
            return isset(()=>this.deviceConfiguration.humidity.configuration.thresholds[1]) && this.deviceConfiguration.humidity.configuration.thresholds[1] !== false;
        },
        hasChanges(){
            return this.snappedDeviceConfiguration !== JSON.stringify(this.deviceConfiguration);
        }
    },
    methods:{
        timeString(minutes, ifZero){
            if(minutes === 0){
                return ifZero
            }
            hours = Math.floor(minutes/60)
            minutes = minutes%60
            return (hours?(hours + (hours>1?" hours ":" hour ")):"") + (minutes?(minutes + (minutes>1?" minutes":" minute")):"")
        },
        rangeString(range, unit, disabled, type){
            if(range[0] === false && range[1] === false){
                return disabled;
            }
            if(range[0] !== false && range[1] === false){
                return $trans("allowed %(type)s above %(lower_bound)s%(unit)s",{lower_bound: range[0], unit: unit, type: $trans(type)});
            }
            if(range[0] === false && range[1] !== false){
                return $trans("allowed %(type)s below %(upper_bound)s%(unit)s",{upper_bound: range[1], unit: unit, type: $trans(type)});
            }
            return $trans("allowed %(type)s between %(lower_bound)s%(unit)s and %(upper_bound)s%(unit)s",{lower_bound: range[0], upper_bound: range[1], unit: unit, type: $trans(type)});
        },
        thresholdsUpdateValue(type, lowerEnabled, newValue){
            if(Array.isArray(newValue)){
                Vue.set(this.deviceConfiguration[type].configuration.thresholds, 0, parseInt(newValue[0]));
                Vue.set(this.deviceConfiguration[type].configuration.thresholds, 1, parseInt(newValue[1]));
            }
            else if(lowerEnabled){
                Vue.set(this.deviceConfiguration[type].configuration.thresholds, 0, parseInt(newValue));
            }
            else{
                Vue.set(this.deviceConfiguration[type].configuration.thresholds, 1, parseInt(newValue));
            }
        },
        thresholdsUpdateSlider(type, lowerEnabled, upperEnabled){
            if(!isset(()=>this.deviceConfiguration[type].capabilities))
                return {};
            let usedStep = Math.max(this.deviceConfiguration[type].capabilities.step, 1);
            const commonOptions = {
                step: usedStep,
                range: {
                    min: Math.max(this.deviceConfiguration[type].capabilities.min + usedStep, -127),
                    max: Math.min(this.deviceConfiguration[type].capabilities.max - usedStep, 127)
                }
            };
            if(lowerEnabled && upperEnabled){
                return Object.assign({
                    start: [this.deviceConfiguration[type].configuration.thresholds[0], this.deviceConfiguration[type].configuration.thresholds[1]], //TODO - use value from previous configuration
                    margin: usedStep,
                    connect: true,
                    behaviour: 'drag',
                }, commonOptions);
            }
            else if(lowerEnabled){
                return Object.assign({
                    start: [this.deviceConfiguration[type].configuration.thresholds[0]],
                    connect: 'upper',
                }, commonOptions);
            }
            else if(upperEnabled){
                return Object.assign({
                    start: [this.deviceConfiguration[type].configuration.thresholds[1]],
                    connect: 'lower',
                }, commonOptions);
            }
            return {};
        },
        temperatureThresholdsUpdateSlider(){
            return this.thresholdsUpdateSlider('temperature', this.temperatureThresholdLowerEnabled, this.temperatureThresholdUpperEnabled);
        },
        humidityThresholdsUpdateSlider(){
            return this.thresholdsUpdateSlider('humidity', this.humidityThresholdLowerEnabled, this.humidityThresholdUpperEnabled);
        },
        async sendConfiguration(){
            this.loader = true;
            try{
                let ret = await axios.post(this.updateUrl, this.deviceConfiguration);
                this.$emit('configuration-sent', this.deviceConfiguration);
            }
            catch(e){
                console.error(e);
            }
            this.loader = false;
        }
    },
    mounted(){
        //temperature
        this.temperaturePeriodicSliderOptions = {
            start: getIfIsset(()=>this.deviceConfiguration.temperature.configuration.periodic, 0),
            range: {
                min: 0,
                max: 255
            }
        };
        this.temperatureThresholdsSliderOptions = this.temperatureThresholdsUpdateSlider();

        //humidity
        this.humidityPeriodicSliderOptions = {
            start: getIfIsset(()=>this.deviceConfiguration.humidity.configuration.periodic, 0),
            range: {
                min: 0,
                max: 255
            }
        };
        this.humidityThresholdsSliderOptions = this.humidityThresholdsUpdateSlider();
    },
    data(){
        return {
            loader: false,
            deviceConfiguration: {},
            temperatureThresholdsSliderOptions: {},
            temperaturePeriodicSliderOptions: {},
            humidityThresholdsSliderOptions: {},
            humidityPeriodicSliderOptions: {},
            snappedDeviceConfiguration: undefined,
            hitEvents:[
                { value:0 , text: $trans("Hit events disabled") },
                { value:1 , text: $trans("Extremly low hit sensitivity") },
                { value:2 , text: $trans("Very low hit sensitivity") },
                { value:3 , text: $trans("Low hit sensitivity") },
                { value:4 , text: $trans("Regular hit sensitivity") },
                { value:5 , text: $trans("High hit sensitivity") },
                { value:6 , text: $trans("Very high hit sensitivity") },
                { value:7 , text: $trans("Extreme hit sensitivity") },
            ]
        }
    }
}
</script>